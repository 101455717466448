<script setup>
import { ref, computed } from 'vue'
import { SDialog } from '@shein-aidc/sui-dialog'
import MainCartItem from 'public/src/pages/cart_new/components/cartItem/template/MainCartItem.vue'
import BusinessCartMask from 'public/src/pages/common/business-cart/components/BusinessCartMask.vue'
import { deleteItemReport } from '../utils/analysis'
import { isCustomizationProduct } from '../utils/index'
import { hexToRgb } from '@shein/common-function'


/**
 * 非标商品行删除弹窗
 */

const emit = defineEmits(['update:visible', 'cancel', 'confirm', 'close'])

// props
const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  },
  language: {
    type: Object,
    default: () => ({})
  },
  uiConfig: {
    type: Object,
    default: () => ({})
  },
  visible: {
    type: Boolean,
    default: false
  }
})

// data
const isShow = computed({
  get: () => props.visible,
  set: val => emit('update:visible', val)
})
const config = ref({
  // 黑五角标
  GoodsBlackFridayFlag: {
    isShow: false,
  },
  GoodsImg: {
    props({ data }) {
      const imgUrl = data.value.product?.goods_img || ''
      // 是否是定制商品
      const isCustomGood = isCustomizationProduct(data.value)
      return {
        isClick: false,
        isShowMask: false,
        isLazyLoad: false,
        isCutImg: !isCustomGood,
        imgUrl,
      }
    }
  },
  // 折扣角标
  GoodsDiscountFlag: {
    props({ data }) {
      return {
        isShow: !!data.value.aggregateProductBusiness?.discountSubscript && !data.value?.aggregateProductBusiness?.discountPriceStyle,
        bgColor: data.value.aggregateProductBusiness?.discountSubscript?.backgroundColor,
        fontColor: data.value.aggregateProductBusiness?.discountSubscript?.textColor,
        unitDiscount: data.value.aggregateProductBusiness?.discountSubscript?.value,
        isShowSaleDiscount: !!data.value.aggregateProductBusiness?.discountSubscript?.additionalValue,
        saleDiscount: data.value.aggregateProductBusiness?.discountSubscript?.additionalValue,
        isShowMask: false
      }
    }
  },
  // 商品腰带
  GoodsBelt: {
    props({ data }) {
      const bgColor = data.value.aggregateProductBusiness?.productImgBelt?.bgColor || ''
      const bgColorAlpha = data.value.aggregateProductBusiness?.productImgBelt?.bgColorAlpha || ''
      const textColor = data.value.aggregateProductBusiness?.productImgBelt?.textColor || ''
      const beltText = data.value.aggregateProductBusiness?.productImgBelt?.text || ''
      return {
        bgColor: hexToRgb({ hex: bgColor, opa: bgColorAlpha }) || 'rgba(0, 0, 0, 0.6)',
        fontColor: textColor,
        beltText,
        isShowMask: false
      }
    }
  },
  GoodsTitle: {
    props() {
      return {
        fontSize: '12px',
        isClick: false,
        isShowMask: false,
        lineHeight: '14px',
      }
    }
  },
  // 标题图标
  GoodsTitleIcon: {
    isShow: false,
  },
  // 行内店铺头
  GoodsStore: {
    isShow: false,
  },
  // 找同款按钮版
  FindSimilarText: {
    isShow: false,
  },
  // 商品主图定制
  GoodsCustomContent: {
    isShow: false,
  },
  // 营销标签
  PromotionTag: {
    isShow: false,
  },
  // 代金券标签
  VoucherTag: {
    isShow: false,
  },
  // 快速发货标签
  QuickShipTag: {
    isShow: props.uiConfig.showQuickShip === '1',
    props({ data }) {
      const quickShipInfo = data.value.aggregateProductBusiness?.productTags?.find(item => item.type === 'quickShip')
      return {
        isShow: !!quickShipInfo,
        isShowMask: false,
        text: quickShipInfo?.view?.text + (quickShipInfo?.view?.suffixText ? ` | ${quickShipInfo?.view?.suffixText}` : ''),
        isShowNewStyle: quickShipInfo?.style == '1'
      }
    }
  },
  // 免邮标签
  FreeShippingTag: {
    isShow: props.uiConfig.showFreeShip === '1',
    props({ data }) {
      const freeShippingInfo = data.value.aggregateProductBusiness?.productTags?.find(item => item.type === 'freeShipping')
      return {
        isShow: !!freeShippingInfo,
        isShowMask: false,
        text: freeShippingInfo?.view?.text
      }
    }
  },
  // 环保标签
  evoluTag: {
    props({ data }) {
      const evoluSheinInfo = data.value.aggregateProductBusiness?.productTags?.find(item => item.type === 'evoluShein')
      return {
        isShow: !!evoluSheinInfo,
        isShowMask: false,
        text: evoluSheinInfo.view.text
      }
    }
  },
  MorePromotionTag: {
    isShow: props.uiConfig.showMorePromotion === '1',
    props({ data }) {
      const morePromotion = data.value?.aggregateProductBusiness?.productTags?.find((item) => item.type === 'morePromotion')
      return {
        isShow: !!morePromotion,
        disabledIcon: true,
        text: morePromotion?.view?.text,
      }
    },
  },
  // 用户行为标签
  BehaviorLabel: {
    isShow: false,
  },
  // 商品价格
  GoodsPrice: {
    props({ data }) {
      return {
        salePriceFont: 12,
        isShowSalePrice: true,
        salePrice: data.value.aggregateProductBusiness?.priceData?.unitPrice?.price?.amountWithSymbol,
        salePriceColor: data.value.aggregateProductBusiness?.priceData?.unitPrice?.color,
        isShowOriginalPrice: !!data.value.aggregateProductBusiness?.priceData?.originalPrice,
        originalPrice: data.value.aggregateProductBusiness?.priceData?.originalPrice?.price?.amountWithSymbol,
        originPriceText: data.value.aggregateProductBusiness?.priceData?.originalPrice?.description,
        isShowOriginalPriceText: !!data.value.aggregateProductBusiness?.priceData?.originalPrice?.description,
        isShowOriginalPricePopover: !!data.value.aggregateProductBusiness?.priceData?.originalPrice?.tip,
        originPricePopoverText: data.value.aggregateProductBusiness?.priceData?.originalPrice?.tip?.desc,
        isShowSuggestPrice: !!data.value.aggregateProductBusiness?.priceData?.lowestPrice,
        suggestPrice: data.value.aggregateProductBusiness?.priceData?.lowestPrice?.price?.amountWithSymbol,
        suggestPriceText: data.value.aggregateProductBusiness?.priceData?.lowestPrice?.description,
        isShowSheinVipIcon: false,
        isShowRomweVipIcon: false,
        isShowPromotionIcon: true,
        isShowPremiumMemberIcon: !!data.value.aggregateProductBusiness?.priceData?.unitPrice?.icon,
        isShowTimeIcon: false,
        isShowAppOnlyIcon: false,
        isShowBrandSaleIcon: false,
        isShowFlashSaleIcon: false,
        premiumMemberIconColor: data.value.aggregateProductBusiness?.priceData?.unitPrice?.color
      }
    }
  },
  // 找同款图标版
  FindSimilarIcon: {
    isShow: false,
  },
  // 商品删除
  GoodsDeleteButton: {
    isShow: false,
  },
  GoodsAddWish: {
    isShow: false,
  },
  GoodsQty: {
    isShow: false,
  },
  EstimatedPrice: {
    props({ data }) {
      const text = data.value.aggregateProductBusiness?.estimatedTip
      return {
        isShow: !!text,
        isShowEstimated: true,
        isShowDiscount: false,
        isHover: false,
        hideCountdown: true,
        text,
      }
    }
  },
  GoodsSaleAttr: {
    props({ data }) {
      const isAdditionGoods = data.value.aggregateProductBusiness?.isAdditionGoods == '1'
      return {
        isShow: !!(data.value.product?.color_image || data.value.aggregateProductBusiness?.goodsAttr),
        type: 'text',
        size: '12px',
        height: '15px',
        isClick: false,
        isShowMask: isAdditionGoods,
        hideArrow: isAdditionGoods,
        colorImage: data.value.product?.color_image,
        goodsAttr: data.value.aggregateProductBusiness?.goodsAttr
      }
    },
  },
})

// methods
const handleCancel = () => {
  emit('cancel', props.data)
  isShow.value = false
}
const handleConfirm = () => {
  emit('confirm', props.data)
  isShow.value = false
}
const handleClose = () => {
  emit('close', props.data)
  isShow.value = false
}
const handleExpose = () => {
  deleteItemReport({ item: props.data })
}
</script>

<template>
  <div
    v-if="isShow"
    class="bc-item-del"
  >
    <BusinessCartMask
      class="bc-item-del__mask"
      @click="handleClose"
    />
    <SDialog
      v-model:visible="isShow"
      :show-close="false"
      :modal="false"
    >
      <template #title>
        {{ language.SHEIN_KEY_PC_30038 }}
      </template>
      <div
        class="bc-item-del__item"
      >
        <MainCartItem
          :config="config"
          :data="data"
          :language="language"
          @expose="handleExpose"
        />
      </div>
      <template #footer>
        <SButtonGroup
          :width="'100%'"
          :num="2"
          :hor="true"
        >
          <SButtonGroupItem
            @click="handleCancel"
          >
            {{ language.SHEIN_KEY_PC_30061 }}
          </SButtonGroupItem>
          <SButtonGroupItem
            :type="['primary']"
            @click="handleConfirm"
          >
            {{ language.SHEIN_KEY_PC_30060 }}
          </SButtonGroupItem>
        </SButtonGroup>
      </template>
    </SDialog>
  </div>
</template>

<style lang="less" scoped>
.bc-item-del {
  &__item {
    width: 416px;
    background-color: #fff;
    padding: 12px;
  }
  &__mask {
    z-index: 2;
    border-radius: 12px 12px 0 0;
  }
  /deep/ .sui-dialog .sui-dialog__wrapper {
    padding: 19px 32px 22px 32px;
    width: 480px;
    min-height: 258px;
    background-image: var(--delete-background-img);
    background-size: cover;
  }
  /deep/ .sui-dialog .sui-dialog__title-wrap {
    padding: 0 32px;
    background: transparent;
  }
  /deep/ .sui-dialog .sui-dialog__title {
    white-space: nowrap;
    text-overflow: ellipsis;
    background: transparent;
    font-size: 18px;
    line-height: 21px;
    font-weight: bold;
    margin-bottom: 17px;
    color: #fff;
    overflow: hidden;
  }
  /deep/ .sui-dialog .sui-dialog__body {
    padding: 0;
    min-height: 114px;
    margin-bottom: 21px;
    text-align: left;
  }
  /deep/ .main-carts__left {
    margin-right: 10px;
    width: 90px;
    height: 90px;
  }
  /deep/ .main-carts__right-top {
    margin-bottom: 6px;
  }
  /deep/ .main-carts__right {
    min-height: 80px;
  }
  /deep/ .main-carts__info {
    margin-bottom: 6px;
  }
}
</style>
