<script setup>
import { computed } from 'vue'
import { SCheckbox } from '@shein-aidc/sui-checkbox'

const props = defineProps({
  isPromotion: {
    type: Boolean,
    default: false
  },
  isEmpty: {
    type: Boolean,
    default: false
  },
  groupInfo: {
    type: Object,
    default: () => {}
  },
  header: {
    type: Object,
    default: () => {}
  }
})

const emit = defineEmits(['modify'])

const styles = computed(() => {
  return {
    '--bg-color': props.isPromotion ? 'linear-gradient(181deg, #FFF4CD 0.74%, #FFF 71.42%)' : '#fff',
    '--color': props.isPromotion ? '#FA6338' : '#222',
  }
})
const checked = computed(() => props.groupInfo?.isChecked === '1')
const title = computed(() => props.header?.title || '')
const number = computed(() => props.header?.number || '')
const totalPrice = computed(() => props.header?.totalPrice || '')

const handleChange = () => {
  emit('modify', !checked.value)
}
</script>

<template>
  <div
    class="bc-group-header"
    :class="[
      'bc-group-header',
      {
        'bc-group-header_empty': isEmpty,
      }
    ]"
    :style="styles"
  >
    <SCheckbox
      v-if="!isEmpty"
      v-model="checked"
      outside-check
      @change="handleChange"
    />
    <div
      :class="[
        'bc-group-header__content',
        {
          'bc-group-header__content_empty': isEmpty
        }
      ]"
    >
      <span class="bc-group-header__title">{{ title }}</span>
      <span
        v-if="number"
        class="bc-group-header__total"
      >({{ number }})</span>
    </div>
    <span class="bc-group-header__price">{{ totalPrice }}</span>
  </div>
</template>

<style lang="less" scoped>
.bc-group-header {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  width: 100%;
  height: 48px;
  border-radius: 10px;
  background: var(--bg-color);
  z-index: 1;
  transition: border-radius 0.1s linear;
  &.bc-group-header_empty {
    position: relative;
  }
  ::v-deep(.sui-checkbox){
    margin-right: 0;
  }
  ::v-deep(.sui-checkbox__label-text){
    display: none;
  }
  .bc-group-header__content {
    flex: 1;
    display: flex;
    align-items: center;
    margin-left: 16px;
    font-size: 16px;
    font-weight: 700;
    color: var(--color);
    overflow: hidden;
  }
  .bc-group-header__content_empty {
    margin-left: 36px;
  }
  .bc-group-header__title {
    font-family: Arial;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .bc-group-header__total {
    margin-left: 4px;
    font-family: "SF UI Text";
  }
  .bc-group-header__price {
    margin-left: 15px;
    color: #222;
    font-size: 16px;
    font-weight: 700;
  }
}
</style>
