<script setup>
import { hexToRgb } from '@shein/common-function'
import { ref, inject, nextTick, computed } from 'vue'
import { SCheckbox } from '@shein-aidc/sui-checkbox'
import { SMessage } from '@shein-aidc/sui-message'
import { handleUpdateAndUpdateAttrErr } from 'public/src/pages/cart_new/utils/index.js'
import {
  selectGoodsReport,
  updateGoodsAttrReport,
  clickQtyInputReport,
  qtyExposeReport,
  inputQtyChangeReport,
  selectQtyChangeReport,
} from 'public/src/pages/common/business-cart/utils/analysis.js'
import { scroll } from 'public/src/pages/common/business-cart/utils/scroll.js'
import { getImageRatio } from 'public/src/pages/common/utils/index.js'
import MainCartItem from 'public/src/pages/cart_new/components/cartItem/template/MainCartItem.vue'
import QuickViewPopover from 'public/src/pages/common/quickView/index.js'
import { isCustomizationProduct } from '../utils/index'
import { UPDATE_CART_INFO_ACTIONS } from '../constants'

/**
 * 非标购物车商品行组件
 */
const emit = defineEmits(['expose'])
const refetchData = inject('refetchData')
const modify = inject('modify')
const updateQty = inject('updateQty')
const openDelete = inject('openDelete')

// props
const props = defineProps({
  /**
   * 商品行数据
   */
  data: {
    type: Object,
    default: () => ({})
  },
  /**
   * 多语言包
   */
  language: {
    type: Object,
    default: () => ({})
  },
  /**
   * 非标界面配置
   */
  uiConfig: {
    type: Object,
    default: () => ({}),
  },
})

// 商品行配置
const config = ref({
  // 商品图片
  GoodsImg: {
    props({ data }) {
      const imgUrl = data.value.product?.goods_img || ''
      // 是否是定制商品
      const isCustomGood = isCustomizationProduct(data.value)
      return {
        isClick: false,
        isShowMask: false,
        isLazyLoad: true,
        isCutImg: !isCustomGood,
        imgUrl,
      }
    }
  },
  // 黑五角标
  GoodsBlackFridayFlag: {
    isShow: false,
  },
  // 折扣角标
  GoodsDiscountFlag: {
    props({ data }) {
      return {
        isShow: !!data.value.aggregateProductBusiness?.discountSubscript && !data.value?.aggregateProductBusiness?.discountPriceStyle,
        bgColor: data.value.aggregateProductBusiness?.discountSubscript?.backgroundColor,
        fontColor: data.value.aggregateProductBusiness?.discountSubscript?.textColor,
        unitDiscount: data.value.aggregateProductBusiness?.discountSubscript?.value,
        isShowSaleDiscount: !!data.value.aggregateProductBusiness?.discountSubscript?.additionalValue,
        saleDiscount: data.value.aggregateProductBusiness?.discountSubscript?.additionalValue,
        isShowMask: false
      }
    }
  },
  // 商品腰带
  GoodsBelt: {
    props({ data }) {
      const bgColor = data.value.aggregateProductBusiness?.productImgBelt?.bgColor || ''
      const bgColorAlpha = data.value.aggregateProductBusiness?.productImgBelt?.bgColorAlpha || ''
      const textColor = data.value.aggregateProductBusiness?.productImgBelt?.textColor || ''
      const beltText = data.value.aggregateProductBusiness?.productImgBelt?.text || ''
      return {
        bgColor: hexToRgb({ hex: bgColor, opa: bgColorAlpha }) || 'rgba(0, 0, 0, 0.6)',
        fontColor: textColor,
        beltText,
        isShowMask: false
      }
    }
  },
  GoodsTitle: {
    props() {
      return {
        isClick: false,
        isShowMask: false
      }
    }
  },
  // 标题图标
  GoodsTitleIcon: {
    isShow: false,
  },
  // 行内店铺头
  GoodsStore: {
    isShow: false,
  },
  // 找同款按钮版
  FindSimilarText: {
    isShow: false,
  },
  // 商品主图定制
  GoodsCustomContent: {
    isShow: false,
  },
  // 营销标签
  PromotionTag: {
    isShow: false,
  },
  // 代金券标签
  VoucherTag: {
    isShow: false,
  },
  // 快速发货标签
  QuickShipTag: {
    isShow: props.uiConfig.showQuickShip === '1',
    props({ data }) {
      const quickShipInfo = data.value.aggregateProductBusiness?.productTags?.find(item => item.type === 'quickShip')
      return {
        isShow: !!quickShipInfo,
        isShowMask: false,
        text: quickShipInfo?.view?.text,
        isShowNewStyle: quickShipInfo?.style == '1',
        isShowTimeText: !!quickShipInfo?.view?.suffixText,
        timeText: quickShipInfo?.view?.suffixText
      }
    }
  },
  // 免邮标签
  FreeShippingTag: {
    isShow: props.uiConfig.showFreeShip === '1',
    props({ data }) {
      const freeShippingInfo = data.value.aggregateProductBusiness?.productTags?.find(item => item.type === 'freeShipping')
      return {
        isShow: !!freeShippingInfo,
        isShowMask: false,
        text: freeShippingInfo?.view?.text
      }
    }
  },
  // 环保标签
  EvoluTag: {
    props({ data }) {
      const evoluSheinInfo = data.value.aggregateProductBusiness?.productTags?.find(item => item.type === 'evoluShein')
      return {
        isShow: !!evoluSheinInfo,
        isShowMask: false,
        text: evoluSheinInfo?.view?.text
      }
    }
  },
  MorePromotionTag: {
    isShow: props.uiConfig.showMorePromotion === '1',
    props({ data }) {
      const morePromotion = data.value?.aggregateProductBusiness?.productTags?.find((item) => item.type === 'morePromotion')
      return {
        isShow: !!morePromotion,
        isShowCouponDiscount: false,
        isShowPointDiscount: false,
        isShowSingleOtherDiscount: false,
        isShowPromotionDiscounts: true,
        popoverTitle: morePromotion?.data?.morePromotion?.title,
        promotionDiscounts: morePromotion?.data?.morePromotion?.items.map(({ data: { title, savePrice } }) => ({ label: title, value: `: ${savePrice}` })),
        text: morePromotion?.view?.text,
      }
    },
  },
  // 用户行为标签
  BehaviorLabel: {
    isShow: false,
  },
  // 商品价格
  GoodsPrice: {
    props({ data }) {
      return {
        salePrice: data.value.aggregateProductBusiness?.priceData?.unitPrice?.price?.amountWithSymbol,
        salePriceColor: data.value.aggregateProductBusiness?.priceData?.unitPrice?.color,
        isShowOriginalPrice: !!data.value.aggregateProductBusiness?.priceData?.originalPrice,
        originalPrice: data.value.aggregateProductBusiness?.priceData?.originalPrice?.price?.amountWithSymbol,
        originPriceText: data.value.aggregateProductBusiness?.priceData?.originalPrice?.description,
        isShowOriginalPriceText: !!data.value.aggregateProductBusiness?.priceData?.originalPrice?.description,
        isShowOriginalPricePopover: !!data.value.aggregateProductBusiness?.priceData?.originalPrice?.tip,
        originPricePopoverText: data.value.aggregateProductBusiness?.priceData?.originalPrice?.tip?.desc,
        isShowSuggestPrice: !!data.value.aggregateProductBusiness?.priceData?.lowestPrice,
        suggestPrice: data.value.aggregateProductBusiness?.priceData?.lowestPrice?.price?.amountWithSymbol,
        suggestPriceText: data.value.aggregateProductBusiness?.priceData?.lowestPrice?.description,
        isShowSheinVipIcon: false,
        isShowRomweVipIcon: false,
        isShowPromotionIcon: true,
        isShowPremiumMemberIcon: !!data.value.aggregateProductBusiness?.priceData?.unitPrice?.icon,
        isShowTimeIcon: false,
        isShowAppOnlyIcon: false,
        isShowBrandSaleIcon: false,
        isShowFlashSaleIcon: false,
        premiumMemberIconColor: data.value.aggregateProductBusiness?.priceData?.unitPrice?.color
      }
    }
  },
  // 找同款图标版
  FindSimilarIcon: {
    isShow: false,
  },
  // 商品删除
  GoodsDeleteButton: {
    type: 'v2',
    events: {
      onClick({ data }) {
        openDelete(data.value)
      }
    }
  },
  GoodsAddWish: {
    isShow: false,
  },
  GoodsQty: {
    props({ data }) {
      let max = 99
      const { maxCanPurchaseQuantity } = data.value.aggregateProductBusiness || {}
      if (!!maxCanPurchaseQuantity) {
        max = Math.min(maxCanPurchaseQuantity, 99)
      }
      return {
        isShow: data.value.aggregateProductBusiness?.isAdditionGoods != '1',
        max,
        isSoldoutByInventory: false,
        isShowMask: false,
        isClick: true,
      }
    },
    events: {
      async onChange({ data, event }) {
        // 缓存更新前的值
        const num = data.value.quantity
        // 更新成字符串，避免后续恢复原值不触发更新
        data.value.quantity = String(event.quantity)

        const handleReport = (item, result) => {
          if (event.action === 'input') {
            inputQtyChangeReport({ item, result })
          }
          if (event.action === 'select') {
            selectQtyChangeReport({ item, result })
          }
        }

        const _data = {
          cartId: data.value.id,
          quantity: event.quantity,
          isChecked: data.value.is_checked,
          appendIdList: data.value.appendIds,
          onError: (res) => {
            // 更新失败，恢复原值
            data.value.quantity = num
            handleReport(data.value, 0)
            if (res?.msg) {
              SMessage({
                message: res.msg,
                type: 'error'
              })
            }
          },
          onSuccess: () => {
            handleReport(data.value, 1)
          }
        }
        updateQty(_data)
      },
      onReport({ event, data }) {
        if (event.type === 'clickInput') {
          clickQtyInputReport({ item: data.value })
        }
        if (event.type === 'clickNullValueOption') {
          selectQtyChangeReport({ item: data.value, result: '-' })
        }
      },
      onExpose({ data }) {
        qtyExposeReport({ item: data.value })
      }
    }
  },
  EstimatedPrice: {
    props({ data }) {
      const text = data.value.aggregateProductBusiness?.estimatedTip
      return {
        isShow: !!text,
        isShowEstimated: true,
        isShowDiscount: false,
        hideCountdown: true,
        isHover: false,
        text,
      }
    }
  },
  GoodsSaleAttr: {
    props({ data }) {
      const isAdditionGoods = data.value.aggregateProductBusiness?.isAdditionGoods == '1'
      return {
        isShow: !!(data.value.product?.color_image || data.value.aggregateProductBusiness?.goodsAttr),
        loading: data.value.isOpenQuickView,
        isShowMask: isAdditionGoods,
        isClick: !isAdditionGoods,
        hideArrow: isAdditionGoods,
        colorImage: data.value.product?.color_image,
        goodsAttr: data.value.aggregateProductBusiness?.goodsAttr
      }
    },
    events: {
      async onClick({ data, language }) {
        const toggleLoading = () => {
          data.value.isOpenQuickView = !data.value.isOpenQuickView
        }
        toggleLoading()
        if (data.value.isCustomGood) {
          SMessage({
            htmlString: language.value.SHEIN_KEY_PC_27388,
            type: 'warning',
          })
          return
        }

        const getSelectedAttrIndex = () => {
          const selectedAttrIndex = {}
          const attr = data.value.attr || null
          const sku_sale_attr = data.value.product?.sku_sale_attr || []
          if(attr) {
            selectedAttrIndex[attr.attr_id] = { attr_value_id: attr.attr_value_id }
          }
          sku_sale_attr && sku_sale_attr.forEach(item => {
            if(item.is_main != '1' && !selectedAttrIndex[item.attr_id]) {
              selectedAttrIndex[item.attr_id] = { attr_value_id: item.attr_value_id }
            }
          })
          return selectedAttrIndex
        }
        const handleUpdateOrDone = async (res) => {
          if (+res.code === 0) {
            await refetchData({ action: UPDATE_CART_INFO_ACTIONS.UPDATE_ATTR, isShowLoading: true })
            if(data.value.mall_code != res.info.cart.mall_code || data.value.id != res.info.cart.id){
              nextTick(() => {
                scroll(res.info.cart.id)
              })
            }
          } else {
            let errorMessage = handleUpdateAndUpdateAttrErr(res, props.language, data.value)
            errorMessage.msg && SMessage({
              message: errorMessage.msg,
              type: errorMessage.times ? 'error' : 'warning',
              offset: 180,
              duration: errorMessage.times || 3000
            })
          }
        }
        QuickViewPopover.show({
          goodsId: data.value.product.goods_id,
          imgRatio: await getImageRatio(data.value.product.goods_img),
          mallCode: data.value.mall_code,
          showPromotionTagInfo: false,
          extendForm: {
            id: data.value.id,
            quantity: +data.value.quantity,
            selectedAttrIndex: getSelectedAttrIndex(),
          },
          config: {
            isUpdateButton: true,
            hideViewFullDetail: true,
            hideWishlistBtn: true,
            showOneClickPay: false,
            showPromotionTagInfo: false,
          },
          callback: {
            handleOpenQuickViewDone() {
              toggleLoading()
              updateGoodsAttrReport({
                sku_id: data.value.product.goods_sn,
              })
            },
            // 点击更新按钮之后的回调
            handleUpdateDone: handleUpdateOrDone,
            // 加车成功之后的回调
            handleAddToCartDone: handleUpdateOrDone,
          }
        })
      }
    }
  },
})

// methods
const handleCheckedItem = async ({ checked = false }) => {
  let targetItem = props.data
  if((parseInt(targetItem.quantity) > parseInt(targetItem.realTimeInventory))){
    SMessage({
      message: props.language.SHEIN_KEY_PC_21623,
      type: 'error'
    })
    return
  }
  const data = {
    cartIdList: [targetItem.id],
    operationType: checked ? '1' : '2',
    appendIdList: targetItem.appendIds,
  }
  await modify(data)
  selectGoodsReport({
    is_select: checked ? 0 : 1,
    select_type: 1,
  })
}

const checked = computed(() => props.data.is_checked == '1')
const disabledCheckbox = computed(() => props.data.aggregateProductBusiness?.isAdditionGoods == '1')
const styles = computed(() => {
  return {
    '--bg-color': props.data.aggregateProductBusiness?.productType == 'payMemberGift' ? '#fffbee' : '#fff'
  }
})
</script>

<template>
  <div
    class="bc-cart-item"
    :style="styles"
  >
    <div class="bc-cart-item__checked">
      <SCheckbox
        v-model="checked"
        :disabled="disabledCheckbox"
        :gap="'4px'"
        outside-check
        @change="handleCheckedItem"
      />
    </div>
    <div class="bc-cart-item__item">
      <MainCartItem
        :data="data"
        :config="config"
        :language="language"
        @expose="emit('expose')"
      />
    </div>
  </div>
</template>

<style lang="less" scoped>
.bc-cart-item {
  display: flex;
  align-items: center;
  background-color: var(--bg-color);
  &__checked {
    flex-shrink: 0;
    width: 20px;
    margin-right: 16px;
  }
  &__item {
    flex: 1;
  }
  :deep(.main-carts__wrap) {
    background-color: var(--bg-color);
  }
}
</style>
