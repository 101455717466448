/**
 * 触发更新购物车信息的action
 */
export const UPDATE_CART_INFO_ACTIONS = {
  /**
   * 查车
   */
  FETCH: 'fetch',
  /**
   * 更新数量
   */
  UPDATE_QUANTITY: 'updateQuantity',
  /**
   * 删除
   */
  DELETE: 'delete',
  /**
   * 勾选/取消勾选
   */
  MODIFY: 'modify',
  /**
   * 更新销售属性
   */
  UPDATE_ATTR: 'updateAttr',
}


export const DEFAULT_UI_CONFIG = {
  showMorePromotion: '1', // 是否展示 more promotion 标签 '1'展示 '0' 不展示
  showFreeShip: '1', // 是否展示免邮标签 '1' 展示 '0' 不展示
  showQuickShip: '1', // 是否展示快速发货标签 '1' 展示 '0' 不展示
  showRepick: '0', // 是否展示重选按钮 '1' 当前凑单的附属品活动的已加车附属品的商品行上展示该按钮 '0' 不展示
  autoUseCoupon: '0', // 是否自动用券 '1' 中间层还需判断自动用券 abt 是否为开，开则自动用券，关则不自动用券，以防止非标车价格和下单页价格不一致 '0' 不自动使用
  autoUsePoint: '0', // 是否自动用积分 '1' 中间层还需判断自动用积分 abt 是否为开，开则自动用积分，关则不自动用积分，以防止非标车价格和下单页价格不一致 '0' 不自动使用
}

export const NEW_POS_KEYS = [
  'Cartnoteligibleforcoupons',
]

/**
 * 多语言 keys
 */
export const LANGUAGE_KEYS = [
  'SHEIN_KEY_PC_30038',
  'SHEIN_KEY_PC_30060',
  'SHEIN_KEY_PC_30061',
  'SHEIN_KEY_PC_27388',
  'SHEIN_KEY_PC_21623',
]
