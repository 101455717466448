<!-- eslint-disable vue/multi-word-component-names -->
<script setup>

</script>

<template>
  <div class="bs-cart-group">
    <slot name="header"></slot>
    <slot></slot>
  </div>
</template>

<style lang="less" scoped>
.bs-cart-group {
  position: relative;
  width: 100%;
  background-color: #fff;
  transform: translateZ(1px);
  border-radius: 10px;
  padding-bottom: 16px;
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}
</style>
