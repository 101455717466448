import { ref } from 'vue'
import { getLanguage } from '../utils/fetcher'
import { LANGUAGE_KEYS } from '../constants'

export default function useLanguage() {
  const language = ref({})
  
  const getLanguageData = async () => {
    const data = {
      languageKeys: LANGUAGE_KEYS,
    }
    const res = await getLanguage(data)
    if (res.code == 0) {
      language.value = res.info?.result || {}
    }
  }

  return {
    language,
    getLanguageData
  }
}
