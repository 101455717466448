import { ref } from 'vue'
import { getAbt } from '../utils/fetcher'
import { NEW_POS_KEYS } from '../constants'

export default function useAbt() {
  const ABTParams = ref({})

  const getAbtData = async () => {
    const params = {
      posKeys: NEW_POS_KEYS.join(','),
    }
    const res = await getAbt(params)
    if (res.code == 0) {
      ABTParams.value = res.info?.pos || {}
    }
  }

  return {
    ABTParams,
    getAbtData
  }
}
