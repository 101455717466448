<template>
  <!-- @close有bug，会调用两次 -->
  <s-dialog
    :visible="visible"
    :show-close="true"
    :append-to-body="true"
    type="W996"
    class="add-on-dialog"
    @close-from-icon="handleClose"
    @close-from-mask="handleClose"
  >
    <template #title>
      <component
        :is="componentName.head"
        v-if="couponItem"
        :total="total"
        :language="language"
        :coupon-item="couponItem"
        :coupon-info="couponInfo"
        @selected-coupon="handleSelectCoupon"
      />
    </template>

    <div
      da-expose-code="addonListExposeCode"
    >
      <ErrorNetwork
        v-if="couponStatus == COUPON_STATUS.error_network"
        :language="language"
        :status-msg="statusMsg"
        @vue:mounted="analysis17413('promotion_unknown_error')"
        @close="show = false"
      />
      <ErrorDisable
        v-else-if="couponStatus == COUPON_STATUS.error_disable"
        :language="language"
        :status-msg="statusMsg"
        @vue:mounted="analysis17413('promotion_disable_error')"
        @close="show = false"
      />
      <template v-else>
        <section 
          v-if="showFilterArea"
          class="filter-area"
        >
          <FilterBar
            v-if="isCouponAdd"
            ref="filterBar"
            :init-sort-value="paginationParams.sort"
            :language="language"
            @on-change="changeFilter"
          />
          <FilterLabels
            v-if="showStoreFilterLabel"
            :selected-filter-labels="selectedFilterLabels"
            :store-info="storeInfo"
            :language="language"
            @change="handleFilterChange"
          />
        </section>
        <!-- 列表 -->
        <Loading v-show="loading">
          <div
            v-if="loading"
            class="la-ball-pulse"
          >
            <div></div>
            <div></div>
            <div></div>
          </div>
        </Loading>
        
        <EmptyList
          v-if="!loading && !products.length"
          :text="language.SHEIN_KEY_PC_22046"
          :btn-text="language.SHEIN_KEY_PC_22252"
          @reset="handleEmptyReset"
        />

        <List
          v-show="!loading && products.length"
          :item-config="itemConfig"
          :products="products"
          :language="language"
          :page="paginationParams.page"
          :limit="paginationParams.limit"
          :total="total"
          :is-click-to-detail="config.isClickToDetail"
          :type="type"
          :sa="saInfo"
          :new-product-card="true"
          :config="config"
          @page-change="pageChange"
          @update-cart="updateCart"
          @click-item="onClickItem"
          @add-bag="onAddBag"
        />
        <ResetAllFilter
          v-show="showRestAll"
          v-tap="{
            id: '1-7-4-25'
          }"
          :language="language"
          @click="handleResetFilter"
        />
      </template>
    </div>
    <template #footer>
      <div 
        v-if="couponItem && componentName.foot"
        class="addon-foot"
      >
        <BusinessCartFoot 
          ref="BusinessCartFoot"
          type="coupon"
          scene="couponAddItemPopup"
          :state="saInfo.state"
          :promotion-id="couponItem.couponCode"
          :language="language"
          :status="addOnStatus"
          @cart-updated="businessCartUpdated"
          @open-business-cart="onOpenBusinessCart"
        />
        <component
          :is="componentName.foot"
          :language="language"
          :coupon-item="couponItem"
          :config="config"
          @back="viewCart"
        >
          <template #incentivePoint>
            <IncentivePoint
              v-if="!config.isCloseIncentivePoint"
              class="footer__incentive-point"
              :tags="tags"
              :bubble="bubble"
            />
          </template>
        </component>
      </div>
    </template>
  </s-dialog>
</template>

<script>
import { watch } from 'vue'
import FilterBar from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-v2/components/Filter.vue'
import { daEventCenter } from '@/public/src/services/eventCenter/index'
import { daEventCenter as daEventCenterV2 } from '@/public/src/services/eventCenterV2'
import Loading from '@/public/src/pages/common/cartAddItem/components/Loading.vue'
import EmptyList from '@/public/src/pages/common/cartAddItem/components/EmptyList.vue'
import List from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-v2/components/List.vue'

daEventCenter.addSubscriber({ modulecode: '1-7-4' })

daEventCenter.addSubscriber({ modulecode: '2-3' }) // 商卡

const daEventExpose = daEventCenter.getExposeInstance()

import apiReport from 'public/src/pages/common/apiReport'
import { cloneDeep, preloadImgs } from '@shein/common-function'
import { getPageAbt, getLanguage, getInterfaceList } from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-v2/utils/fetcher.js'
import { getCouponInfo } from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-bff/utils/fetcher.js'
import { isRangeUp, getSatisfiedValue } from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-bff/utils/index.js'
import { setCouponDataForAddOnItem } from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-v2/utils/couponData.js'
import { COUPON_STATUS, COUPON_DISABLE_CODES, SECONDARY_COUPON_MAX } from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-v2/utils/constants.js'
import ErrorNetwork from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-v2/layout/ErrorNetwork.vue'
import ErrorDisable from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-v2/layout/ErrorDisable.vue'
import CouponAddHead from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-bff/components/couponAdd/Head.vue'
import NormalHead from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-bff/components/normal/Head.vue'
import NormalFoot from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-bff/components/normal/Foot.vue'
import MultipleHead from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-bff/components/multiple/Head.vue'
import BusinessCartFoot from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-bff/components/businessCartFoot/IndexComp.vue'
import FilterLabels from 'public/src/pages/common/add-on-dialog/components/FilterLabels.vue'
import ResetAllFilter from 'public/src/pages/common/add-on-dialog/components/ResetAllFilter.vue'
import { LabelKeyMap } from 'public/src/pages/common/add-on-dialog/utils/filterLabelConfig.js'
import { useIncentivePoint, AsyncIncentivePointComp as IncentivePoint } 
from 'public/src/pages/cart_new/components/cartAddItem/addForCoupon/addOnDialog-v2/hooks/incentivePoint'
import { sleep } from '@/public/src/pages/common/add-on-dialog/utils/index.js'
import { getProductConfig } from 'public/src/pages/common/add-on-dialog/utils/productConfig.js'
import { SILogger } from 'public/src/pages/common/monitor/index.js'
import { v4 as uuidv4 } from 'uuid'

const getCouponItem = (coupons, code) => {
  return coupons?.find(v => v.couponCode == code)
}

export default {
  name: 'AddOnDialogBff',
  components: {
    FilterBar,
    Loading,
    EmptyList,
    List,
    ErrorNetwork,
    ErrorDisable,
    CouponAddHead,
    NormalHead,
    NormalFoot,
    IncentivePoint,
    MultipleHead,
    BusinessCartFoot,
    FilterLabels,
    ResetAllFilter,
  },
  emits: ['update:visible', 'updateCart'],
  props: {
    queryParams: { // 查询参数用于请求推荐接口
      type: Object,
      default: () => ({})
    },
    visible: {
      type: Boolean,
      default: false
    },
    saInfo: {
      type: Object,
      default: () => ({})
    },
    config: {
      type: Object,
      default: () => ({})
    },
    // 券码
    coupon: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
    // 副券码
    secondaryCoupon: {
      type: Array,
      default: () => ([]),
      // 校验长度最大为 19
      validator: (val) => {
        if (val.length > SECONDARY_COUPON_MAX) {
          console.error('secondaryCoupon length must be less than 19')
          return false
        }
        return true
      }
    },
    // 是否已对副券码进行排序
    // @desc 用于多券场景，副券码排序后不再进行排序
    isSorted: {
      type: Boolean,
      default: false,
    },
    onUpdateCart: {
      type: Function,
      default: () => ()=> ({}),
    },
    onClose: {
      type: Function,
      default: () => ()=> ({}),
    },
    onViewCart: {
      type: Function,
      default: () => ()=> ({}),
    },
    onUpdateBusinessCart: {
      type: Function,
      default: () => ()=> ({}),
    },
    onViewTap: {
      type: Function,
      default: () => ()=> ({}),
    },
    // for store filter label, optional
    storeInfo: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      products: [],
      paginationParams: {
        sort: 0,
        page: 1,
        limit: 10,
      },
      total: 10,
      loading: true,
      isFirst: true,
      language: {},
      abtInfo: {},
      
      addOnInfo: {},
      preCouponInfos: [], // 记录上一次请求时券信息，主要用于对比进度
      originAddOnInfos: {}, // 原始信息，主要用于埋点
      curCouponCode: '', // 正在凑的券
      addCartNum: 0, // 首券加车次数
      otherAddCartNum: 0, // 副券加车次数

      statusCode: '',
      statusMsg: '',
      COUPON_STATUS,

      itemConfig: {},
      recommendQuery: {},
      selectedFilterLabels: [],
      initHaveStoreFilter: false,
    }
  },
  computed: {
    couponInfo() {
      return this.addOnInfo?.newPromotionPopupInfo // 凑单信息
    },
    couponItem() { // 正在凑单的券
      return this.couponInfo?.couponInfos?.find(v => v.isCheck == 1)
    },
    isCouponAdd() {
      return this.saInfo?.state === 'coupon_add'
    },
    commonParams() {
      const { addOnType, sceneId, cateIds, goodsIds, mallCode, quickship_prior } = this.queryParams
      const { goodsPrice = 0, directTag, returnTag } = this.couponItem?.recommendInfo || {}

      return {
        goodsIds: [...new Set(goodsIds)].join(','), // 商品ID,  
        cateIds: [...new Set(cateIds)].join(','), // 类目ID
        jsonRuleId: JSON.stringify(this.abtInfo?.addnewhotJson?.param || {}), // 实验参数
        location: 'addnewhotJson',
        goods_price: (goodsPrice * 100).toFixed(0), // 凑单差额价格(美分)
        mall_code: mallCode || '',
        scene_id: sceneId,
        add_on_type: addOnType,
        include_tsp_id: directTag,
        exclude_tsp_id: returnTag,
        quickship_prior,
      }
    },
    isMultiple() {
      // 0 - 单券, 1 - 多券-A方案, 2 - 多券B方案
      return [1, 2].includes(this.addOnInfo?.styleType)
    },
    isOriginMultiple() {
      return [1, 2].includes(this.originAddOnInfos?.styleType)
    },
    componentName() {
      if (this.isCouponAdd) {
        return { head: 'CouponAddHead' }
      }
      return {
        head: this.isMultiple ? 'MultipleHead' : 'NormalHead',
        foot: 'NormalFoot',
      }
    },
    couponStatus() {
      if (this.statusCode != 0) {
        return COUPON_DISABLE_CODES.includes(+this.statusCode) ? COUPON_STATUS.error_disable : COUPON_STATUS.error_network
      }
      return COUPON_STATUS.normal
    },
    addOnStatus() {
      return getSatisfiedValue(this.couponItem)
    },
    isBusinessCartBottom() {
      const { cartadd_coupon_bottom_style } = this.abtInfo?.CartaddCouponBottomStyle?.param || {}
      return ['A'].includes(cartadd_coupon_bottom_style)
    },
    showFilterArea() {
      return this.isCouponAdd || this.showStoreFilterLabel
    },
    showStoreFilterLabel() {
      return this.initHaveStoreFilter && this.storeInfo.id
    },
    showRestAll() {
      return (this.selectedFilterLabels.length > 0) && this.products.length > 0
    },
  },
  setup(props) {
    const { tags, bubble, init, destroy, update: updateIncentivePoint } = useIncentivePoint({
      addOnType: props.type,
      saInfo: props.saInfo,
    })
    if (props.type && props.type !== 'freeShipping') {
      watch(() => props.visible, (visible) => {
        if (visible) {
          init()
        } else {
          destroy()
        }
      }, {
        immediate: true,
      })
    }
    return {
      tags,
      bubble,
      updateIncentivePoint,
    }
  },

  created() {
    this.preloadImgs()
  },
  mounted() {
    apiReport.report({
      apiPath: 'add_popup/bi_parameter_empty',
      // !!!! 产品要求, 埋点传全部外部组件传入参数 !!!!!
      parameter_detail: { ...this.$props },
    })

    this.handleShow()
  },

  methods: {
    closeDialog() {
      this.$emit('update:visible', false)
    },
    handleClose() {
      if (this.couponItem) {
        this.analysis17414()
        this.onClose(this.couponInfo || {})
      }
      this.closeDialog()
    },
    /**
     * 预加载样式里面的图片
     */
    preloadImgs() {
      if (typeof window === 'undefined') return
      if (!this.isCouponAdd) {
        preloadImgs({
          imgs: [
            'https://img.ltwebstatic.com/images3_acp/2024/03/07/4b/17097929553fe97a73d56de1aed20706fc26417a9c.png',
            'https://img.ltwebstatic.com/images3_acp/2024/03/07/13/1709792922257c678dd2460e7c08bd504ace6a769f.png',
            'https://img.ltwebstatic.com/images3_acp/2024/03/09/76/1709967509cb660c420cbc70fbd2e0b83b857d07b6.png',
            'https://img.ltwebstatic.com/images3_acp/2024/03/09/aa/1709967443b9e064321ff1e0ee9fb7fe4274087dc8.png',
            'https://img.ltwebstatic.com/images3_acp/2024/03/09/ba/1709967501c59d5739257b892ebc9826ce02cff11e.png',
            'https://img.ltwebstatic.com/images3_acp/2024/03/09/50/1709967482911b11356adb5543b59b4b9cbc7f48e8.png',
            'https://img.ltwebstatic.com/images3_acp/2024/03/09/5d/1709967467dabf90e3add0513c087911850aea1345.png',
          ],
        })
      }
    },
    async handleSelectCoupon(coupon) {
      this.analysis17417(coupon)
      this.curCouponCode = coupon?.couponCode
      
      await this.fetchCouponInfo()
      this.$toast(this.language.SHEIN_KEY_PC_30147)
      this.refreshListData()
    },
    viewCart() {
      this.analysis17410()
      const cInfo = {
        ...this.couponItem,
      }
      if (this.config?.continueBtnToClose || window.location.href.includes('/cart')) {
        this.onViewCart({ couponInfo: cInfo })
        this.closeDialog()
        return
      }

      setCouponDataForAddOnItem({ couponInfo: cInfo })
      // 等待埋点事件触发上报后再进行跳转
      setTimeout(() => {
        window.location.href = '/cart'
      })
    },  
    async handleShow() {
      this.resetData()
      this.fecthLanguage()
      await this.fetchPageAbt() // 请求jsonRuleId
      const res = await getProductConfig({ 
        code: 'addonListExposeCode',
        style: {
          addBagBtnStyle: ['freeShipping', 'coupon'].includes(this.type) && ['checkout_shipping_add', 'checkout_shipping_coupon_add'].includes(this.saInfo?.activity_from) ? 'AddText' : 'default'
        }
      })
      this.itemConfig = res?.itemConfig
      this.recommendQuery = res?.recommendQuery

      await this.fetchCouponInfo()  // 请求券数据
      if (this.couponStatus != COUPON_STATUS.normal) return
      await this.fetchList(true)
    },
    async fecthLanguage() {
      const { language } = await getLanguage()
      this.language = language
    },
    async fetchPageAbt() {
      this.abtInfo = await getPageAbt()
    },
    /**
     * 获取优惠券信息
     */
    async fetchCouponInfo() {
      if (this.isFirst) {
        this.curCouponCode = this.coupon
      }

      try {
        const res = await getCouponInfo({
          couponCode: this.coupon,
          curCouponCode: this.curCouponCode,
          subCouponCodes: this.secondaryCoupon?.join(','),
          sort: this.isSorted ? 0 : 1, 
          fixedOrder: this.couponInfo?.fixedOrder,
          activityFrom: this.saInfo?.activity_from,
        })

        if (res.code != 0) {
          this.statusCode = res.code
          this.statusMsg = res.msg
          return
        }
        
        if (this.isFirst) {
          this.originAddOnInfos = cloneDeep(res?.info) || {}
        }

        this.preCouponInfos = cloneDeep(this.addOnInfo?.newPromotionPopupInfo?.couponInfos)
        this.addOnInfo = res?.info || {}
      } finally {
        this.isFirst = false
      }
    },
    handleExpose({ code, total }) {
      let service_error
      if (code == '0' && total == 0) {
        service_error = 'goods_is_empty'
      } else if (code != '0') {
        service_error = code ? code : 'recommend_unknown_error'
      }
      this.analysis17413(service_error)
    },
    async fetchList(isFirst, extraParams = {}) {
      let code
      try {
        this.loading = true
        code = await this.getList(isFirst, extraParams)
      } finally {
        this.loading = false
        this.$nextTick(() => {
          this.initExpose()
        })
        return code
      }
    },
    async getList(isFirst, extraParams = {}) {
      const { page, sort, limit, shop_id } = this.paginationParams
      const { adp, mainGoodsIds } = this.queryParams

      const contextParams = {
        cate_ids: this.commonParams.cateIds,
        goods_ids: this.commonParams.goodsIds,
        goods_price: this.commonParams.goods_price,
        mall_code: this.commonParams.mall_code,
        add_on_type: this.commonParams.add_on_type,
        quickship_prior: this.commonParams.quickship_prior,
        stock_enough: 1, // 是否有库存
        adp, // TR-27530支持外围传入指定商品id
        main_goods_id: [...new Set(mainGoodsIds)].slice(0, 20).join(','),
      }

      // 不能传空字符串
      if (this.commonParams.include_tsp_id) contextParams.include_tsp_id = this.commonParams.include_tsp_id
      if (this.commonParams.exclude_tsp_id) contextParams.exclude_tsp_id = this.commonParams.exclude_tsp_id

      const params = {
        ...this.commonParams,
        ...this.recommendQuery,
        pageEnable: 1,
        req_num: 300,
        pageNum: page, // 页数
        sort,
        limit,
        contextParams,
        shop_id, // for not first query like refresh, page change
        ...extraParams,
      }
      if(this.storeInfo?.id && isFirst) {
        params.shop_id = this.storeInfo.id
      }
      this.products = []
      let { products, total, code } = await getInterfaceList(params, this.itemConfig).catch(() => null)
      if(params.shop_id && products?.length && isFirst) {
        this.initHaveStoreFilter = true
        if(!this.selectedFilterLabels?.includes(LabelKeyMap.STORE)) {
          this.selectedFilterLabels.push(LabelKeyMap.STORE)
          this.paginationParams.shop_id = this.storeInfo.id
        }
      }
      if(this.storeInfo?.id && !products?.length && isFirst) {
        delete params.shop_id
        const res = await getInterfaceList(params, this.itemConfig).catch(() => null)
        products = res?.products
        total = res?.total
        code = res?.code
      }
      if (products?.length) {
        // 填充不满10个时为数据能展示下空数据
        if (products.length < limit) {
          for (let i = 0; i < limit - products.length; i++) {
            products.push({})
          }
        }
        this.products = products
        this.total = total
      }

      if (isFirst) {
        this.handleExpose({ total, code })
      }
      return code
    },

    async changeFilter({ type, item = {} } = {}) {
      this.onViewTap?.({ type: 'FILTER_CHANGE' })
      const { value } = item

      if (type === 'sort') {
        this.paginationParams.sort = value
      }

      this.paginationParams.page = 1
      await this.fetchList()
    },

    pageChange(nextPage) {
      this.paginationParams.page = nextPage
      this.fetchList()

      document.querySelector('.add-on-dialog .sui-dialog__body')?.scrollTo({ top: 0 })
    },

    resetData() {
      this.addCartNum = 0
      this.otherAddCartNum = 0
    },

    // 刷新推荐列表的数据
    async refreshListData() {
      this.paginationParams = {
        sort: 0,
        page: 1,
        limit: 10,
      }
      this.total = 0
      this.products = []
      this.selectedFilterLabels = []
      this.fetchList()
    },

    handleEmptyReset() {
      this.loading = false
      this.refreshListData()
    },

    // 加车完成后事件
    updateCart(payload) {
      this.handleOtherCartAddNum()
      this.fetchCouponInfo() // 更新券数据
      this.updateIncentivePoint(true)
      this.onUpdateCart(payload)
      this.$refs?.BusinessCartFoot?.refetchCarts?.()
    },
    // 非标车加车后事件
    businessCartUpdated(info) {
      if (['updateQuantity', 'delete', 'modify', 'updateAttr'].includes(info?.action)) {
        this.fetchCouponInfo() // 更新券数据
        this.updateIncentivePoint(true)

        this.onUpdateBusinessCart()
        window.cart_module && window.cart_module.reload()
      }
    },

    analysis17413(service_error) {
      const { state, is_satisfied, is_multiple_coupons, promotion_code } = this.getSaExtraData()
      const data = {
        state,
        parameter_detail: this.$props,
        service_error: service_error || '-',
        is_satisfied,
        is_multiple_coupons,
        promotion_code,
        newuser_label: 0,
      }

      daEventCenter.triggerNotice({
        daId: '1-7-4-13',
        extraData: data,
      })

      daEventCenterV2.triggerNotice({
        id: 'expose_cart_add.comp_add-item',
        data,
      })
    },
    analysis17414() {
      const data = this.getSaExtraData()

      daEventCenter.triggerNotice({
        daId: '1-7-4-14',
        extraData: data,
      })

      daEventCenterV2.triggerNotice({
        id: 'click_cart_add_close.comp_add-item',
        data,
      })
    },
    analysis17410() {
      const data = this.getSaExtraData()
      const _eventId = uuidv4()

      daEventCenter.triggerNotice({
        daId: '1-7-4-10',
        extraData: {
          _scene: 1,
          _eventId,
          ...data
        },
      })

      daEventCenterV2.triggerNotice({
        id: 'click_back_to_cart.comp_add-item',
        data: {
          _scene: 1,
          _eventId,
          ...data
        },
      })

      SILogger.logInfo({
        tag: 'CLICK_BACK_TO_CART',
        message: 'click_back_to_cart_stats',
        params: {
          _scene: 1,
          _eventId
        }
      }, {
        immediate: true
      })
    },
    analysis17417(coupon) {
      daEventCenter.triggerNotice({
        daId: '1-7-4-17',
        extraData: {
          position: 'top',
          promotion_code: coupon?.couponCode,
          is_satisfied: getSatisfiedValue(coupon),
        }
      })
    },
    handleOtherCartAddNum() {
      if (!this.isOriginMultiple) {
        this.addCartNum += 1
        return
      }
      const originCouponItem = this.originAddOnInfos?.newPromotionPopupInfo?.couponInfos?.find(v => v.isCheck == 1) // 最初券
      if (originCouponItem?.couponCode !== this.couponItem?.couponCode) {
        this.otherAddCartNum += 1
      } else {
        this.addCartNum += 1
      }
    },
    getSaExtraData() {
      const { couponCode } = this.couponItem || {}
      const originCouponItem = this.originAddOnInfos?.newPromotionPopupInfo?.couponInfos?.find(v => v.isCheck == 1) // 最初券
      const curOriginCouponItem = getCouponItem(this.couponInfo?.couponInfos, originCouponItem?.couponCode)
      const isOtherRangeUp = this.originAddOnInfos?.newPromotionPopupInfo?.couponInfos?.some(originItem => {
        const item = getCouponItem(this.couponInfo?.couponInfos, originItem.couponCode)
        return  originItem.couponCode !== originCouponItem?.couponCode && isRangeUp(item, originItem)
      })

      if (this.isOriginMultiple) {  
        return {
          promotion_code: this.couponInfo?.couponInfos?.map(v => v.couponCode)?.join(','),
          is_satisfied: getSatisfiedValue(this.couponItem),
          coupon_change: isRangeUp(curOriginCouponItem, originCouponItem) ? 1 : 0,
          other_coupon_change: isOtherRangeUp ? 1 : 0,
          add_cart_number: this.addCartNum,
          other_add_cart_number: this.otherAddCartNum,
          is_multiple_coupons: 'multiple',
          is_satisfied_all: this.couponInfo?.couponInfos?.map(v => getSatisfiedValue(v))?.join(','),
          state: this.saInfo.state,
        }
      } else {
        return {
          promotion_code: couponCode,
          is_satisfied: getSatisfiedValue(this.couponItem),
          coupon_change: isRangeUp(this.couponItem, originCouponItem) ? 1 : 0,
          other_coupon_change: '-',
          add_cart_number: this.addCartNum,
          other_add_cart_number: '-',
          is_multiple_coupons: 'single',
          is_satisfied_all: '-',
          state: this.saInfo.state,
        }
      }
    },
    async initExpose() {
      await sleep(500)
      daEventExpose && daEventExpose.subscribe({
        keycode: `addonListExposeCode\`${
          this.itemConfig.daEventExposeId || ''
        }|2-10-2`,
      })
    },
    onOpenBusinessCart() {
      this.onViewTap?.({ type: 'CLICK_BUSINESS_CART_ENTRY' })
    },
    onClickItem() {
      this.onViewTap?.({ type: 'CLICK_PRODUCT_ITEM' })
    },
    onAddBag() {
      this.onViewTap?.({ type: 'ADD_TO_BAG' })
    },
    async handleFilterChange(type, label) {
      let selectStatus
      if(type == 'check') {
        let filterParams = {
          pageNum: 1
        }
        if(label == LabelKeyMap.STORE) {
          filterParams.shop_id = this.storeInfo?.id
        }
        const code = await this.fetchList(false, filterParams)
        if(code == 0) {
          this.selectedFilterLabels.push(label) // update selected status
          selectStatus = 1
          this.paginationParams.shop_id = filterParams.shop_id // success update local query cache for refresh
          this.paginationParams.page = filterParams.pageNum
        } else selectStatus = 0
      } else if(type == 'cancel') {
        const index = this.selectedFilterLabels.findIndex(i => i == label)
        if(index > -1) {
          let shop_id = this.paginationParams?.shop_id // cache
          if(this.paginationParams?.shop_id) delete this.paginationParams.shop_id // cancel shop_id
          const code = await this.fetchList(false, { pageNum: 1 } )
          if(code == 0) {
            this.selectedFilterLabels.splice(index, 1)
            selectStatus = 0
            this.paginationParams.page = 1
          } else {
            selectStatus = 1
            this.paginationParams.shop_id = shop_id // query failed then recover shop_id
          }
        } else selectStatus = 0
      }
      daEventCenter.triggerNotice({
        daId: '1-7-4-23',
        extraData: {
          store_code: this.storeInfo.id,
          is_chosen: selectStatus
        },
      })
    },
    async handleResetFilter() {
      const originParams = Object.assign({}, this.paginationParams)
      this.paginationParams = {
        sort: 0,
        page: 1,
        limit: 10,
      }
      const code = await this.fetchList(false, {})
      if(code == 0) this.selectedFilterLabels = []
      else this.paginationParams = originParams
    },
  }
}
</script>

<style lang="less" scoped>
.add-on-dialog {
  .la-ball-pulse {
    div {
      width: 16px;
      height: 16px;
    }
  }
  :deep(.sui-dialog__title-wrap) {
    padding: 0px;
  }
  :deep(.sui-dialog__title) {
    margin-bottom: 10px;
  }
  :deep(.cart-addItem-list) {
    margin-top: 10px;
  }
  :deep(.sui-dialog__body) {
    min-height: 50vh;
    overflow-x: hidden !important; /* stylelint-disable-line declaration-no-important */
  }
  :deep(.sui-dialog__footer) {
    padding: 0px;
  }
  :deep(.sui-dialog__footer-holder) {
    padding-top: 30px;
  }
  :deep(.sui-dialog__closebtn) {
    background-color: rgba(0,0,0,.3);
    border-radius: 50%;
    top: 4px;
    right: 7px;
    width: 24px;
    height: 24px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    /* stylelint-disable-next-line selector-max-specificity */
    .sui-icons {
      fill: #fff;
    }
  }
  .filter-area {
    padding: unset;
  }
}
.addon-foot {
  position: relative;
  height: 56px;
  padding: 0 48px;
  border-top: 1px solid #EAEAEA;
  display: flex;
  align-items: center;
}
.footer__incentive-point {
  position: absolute;
  right: -1px;
  bottom: calc(100% - 5px);
  z-index: @zindex-hack;
}
</style>
